import React from "react";
import Logo from "../../atoms/Logo";
import { getArtistsNamesFromArtefyCollection } from "../../../utils/utils";

const CollectionBanner = ({ title, description, logo, collection }) => {
  const tempBannerImg = collection?.featured_image?.url
    ? collection?.featured_image?.url
    : "https://64.media.tumblr.com/d7e7f304c3e73dd507c4f6cd88938c4e/c20364aa525860d3-74/s1280x1920/4d009e9717377461162e3e9b9996b828018217e1.jpg";
  return (
    <>
      <div
        className="flex flex-col gap-y-5 justify-center items-center pt-48 pb-10 bg-cover bg-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${tempBannerImg})`,
        }}
      >
        <h1 className="text-center text-5xl font-extrabold">{title}</h1>
        <p className="text-center w-4/5 lg:w-1/2">{description}</p>
        <Logo
          className="w-24 h-24 rounded-full"
          src={logo}
          alt="collection logo"
        />
        {/* <p className="text-center">
          By: <span className="text-red">{getArtistsNamesFromArtefyCollection(collection)}</span>
        </p> */}
      </div>
    </>
  );
};

export default CollectionBanner;
