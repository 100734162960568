import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/Layout";
import CollectionBanner from "../../components/molecules/banners/CollectionBanner";
import TwoColorHeader from "../../components/atoms/TwoColorHeader";
// import CollectWinBanner from "../../components/molecules/banners/CollectWinBanner";
// import OnlineStoreBanner from "../../components/molecules/banners/OnlineStoreBanner";
import urlConstants from "../../constants/urlConstants.js";
import ActiveCollectionDrops from "../../components/organisms/ActiveCollectionDrops";
import NFTCollectionGrid from "../../components/organisms/NFTCollectionGrid";
import BannerGeneric from "../../components/molecules/banners/BannerGeneric";
import { useStaticQuery, graphql } from "gatsby";

import { Helmet } from "react-helmet";

const apiService = require("../../services/apiService.js");

const Collection = ({ pageContext }) => {
  const { strapiBannerShop } = useStaticQuery(graphql`
    query {
      strapiBannerShop {
        image {
          url
        }
        title
        subtext
        button_text
        url
      }
    }
  `);

  const collectionList = pageContext?.collection?.aspen_collections || [];
  const collectionName = pageContext?.collection?.slug;
  const [liveList, setLiveList] = useState([]);
  const [yetToStartList, setYetToStartList] = useState([]);
  const serverTime = useRef(null);

  useEffect(() => {
    apiService
      .getter(urlConstants.getLatestDropsUrl)
      .then((response) => {
        const drops = response.data.drop;
        serverTime.current = response.data.systemNow;
        let nftDropList = [];
        drops.forEach((drop) => {
          const l = drop.aspen_tokens.filter((item) => {
            const collectionSlug =
              item?.aspen_collection?.artefy_collection?.slug;
            return collectionSlug === collectionName;
          });
          if (l.length) {
            nftDropList.push({
              ...drop,
              aspen_tokens: l,
            });
          }
        });
        const currentTime = new Date(response.data.systemNow);
        const live = nftDropList.filter((drop) => {
          return (
            new Date(drop.drop_end) > currentTime &&
            new Date(drop.drop_start) < currentTime
          );
        });
        const yetToStart = nftDropList.filter((drop) => {
          return new Date(drop.drop_start) > currentTime;
        });
        setLiveList(live);
        setYetToStartList(yetToStart);
      })
      .catch((err) => {
        console.log("error occured", err);
      });
  }, [collectionName]);

  return (
    <Layout>
      <Helmet>
        <meta
          name="title"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageContext?.collection?.name} NFT Collection`}
        />
        <meta
          name="description"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageContext?.collection?.description}`}
        />

        {/* OpenGraph tags */}
        <meta
          property="og:title"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageContext?.collection?.name} NFT Collection`}
        />
        <meta
          property="og:description"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageContext?.collection?.name} NFT Collection`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://artefy-strapi-boilerplate-4mdevelopment.s3.ap-southeast-2.amazonaws.com/metal_plus_97826b79e2.png"
        />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="50" />

        {/* Twitter Card tags */}
        <meta
          name="twitter:card"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageContext?.collection?.name} NFT Collection`}
        />
        <meta
          name="twitter:creator"
          content={`${process.env.GATSBY_TWITTER_HANDLE}`}
        />
        <meta
          name="twitter:title"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageContext?.collection?.name} NFT Collection`}
        />
        <meta
          name="twitter:description"
          content={`${pageContext?.collection?.description} | ${pageContext?.collection?.name} NFT Collection`}
        />
        <title>{`${process.env.GATSBY_SITE_NAME} | ${pageContext?.collection?.name} NFT Collection`}</title>
      </Helmet>
      <div>
        <CollectionBanner
          title={pageContext?.collection?.name}
          description={pageContext?.collection?.description}
          logo={pageContext?.collection?.icon?.url}
          collection={pageContext?.collection}
        />
      </div>
      <div className="flex flex-col gap-y-6 justify-center items-center pb-20 pt-24">
        <TwoColorHeader title="Active | Drops" colorClassB="text-primary" />
        <div className="w-full">
          {liveList.length > 0 || yetToStartList.length > 0 ? (
            <>
              <div className="flex flex-wrap p-8 gap-8 justify-center mx-auto max-w-[1900px]">
                {liveList.map((element) => {
                  return (
                    <ActiveCollectionDrops
                      key={element.id}
                      element={element}
                      currentTime={serverTime.current}
                      totalDrops={liveList.length}
                    />
                  );
                })}
                {yetToStartList.map((element) => {
                  return (
                    <ActiveCollectionDrops
                      key={element.id}
                      element={element}
                      currentTime={serverTime.current}
                      totalDrops={yetToStartList.length}
                    />
                  );
                })}
              </div>
            </>
          ) : (
            <div className="flex justify-center items-center flex-col gap-y-4">
              <div className="loader" />
              Getting active drops
            </div>
          )}
        </div>
      </div>
      <div>
        <NFTCollectionGrid
          collectionList={collectionList}
          currentTime={serverTime.current}
          collectionName={collectionName}
        />
      </div>
      <div>
        <BannerGeneric bannerData={strapiBannerShop} className="lg:h-96" />
      </div>
    </Layout>
  );
};

export default Collection;
