import React, { useEffect, useState } from "react";
import Button from "../atoms/Button";
import TwoColorHeader from "../atoms/TwoColorHeader";
import NftCardDetailBuy from "../molecules/nftCards/NftCardDetailBuy";
import NftGridLayout from "../molecules/NftGridLayout";

const NFTCollectionGrid = ({ collectionList, currentTime, collectionName }) => {
  const initialCollections = 8;
  const nextCollections = 4;

  const [filteredCollectionList, setfilteredCollectionList] = useState([]);
  const [finalCollectionList, setFinalCollectionList] = useState([]);
  const [showMoreButton, setShowMoreButton] = useState(false);

  useEffect(() => {
    if (collectionList.length && currentTime) {
      setfilteredCollectionList(
        collectionList.filter((item) => {
          const token = item?.aspen_tokens[0];
          return (
            token && new Date(token?.drop?.drop_end) < new Date(currentTime)
          );
        })
      );
    }
  }, [collectionList, currentTime]);

  useEffect(() => {
    if (filteredCollectionList.length) {
      if (filteredCollectionList.length <= initialCollections) {
        setFinalCollectionList(filteredCollectionList);
        setShowMoreButton(false);
      } else {
        setFinalCollectionList(
          filteredCollectionList.slice(0, initialCollections)
        );
        setShowMoreButton(true);
      }
    }
  }, [filteredCollectionList]);

  const handleLoadMore = () => {
    if (filteredCollectionList.length > finalCollectionList.length) {
      if (
        filteredCollectionList.length - finalCollectionList.length >
        nextCollections
      ) {
        setFinalCollectionList(
          filteredCollectionList.slice(
            0,
            finalCollectionList.length + nextCollections
          )
        );
        setShowMoreButton(true);
      } else {
        setFinalCollectionList(filteredCollectionList);
        setShowMoreButton(false);
      }
    } else {
      setFinalCollectionList(filteredCollectionList);
      setShowMoreButton(false);
    }
  };

  return (
    <>
      {finalCollectionList.length > 0 && (
        <>
          <div className="flex flex-col items-center gap-y-6 mb-20">
            <TwoColorHeader
              title="More from|this collection"
              colorClassB="text-primary"
            />
            <NftGridLayout contentLength={finalCollectionList.length}>
              {finalCollectionList.map((item) => {
                const token = item?.aspen_tokens[0];
                const link = `/marketplace/nfts/${collectionName}/${token.slug}`;
                return (
                  <NftCardDetailBuy
                    key={token?.id}
                    name={token?.name}
                    nftCardImg={token?.featured_image?.url}
                    priceCrypto={token?.drop_price_crypto}
                    priceFiat={token?.drop_price_fiat}
                    artists={token?.artists}
                    totalTokens={token?.aspen_token_lines?.length}
                    tokenId={token?.id}
                    tokenType={token?.aspen_token_type?.name}
                    link={link}
                  />
                );
              })}
            </NftGridLayout>
          </div>

          {showMoreButton && (
            <Button onClick={handleLoadMore} type="secondary">
              Load More
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default NFTCollectionGrid;
