import React, { useEffect, useState } from "react";
import NftHeroCardTime from "../molecules/nftCards/NftHeroCardTime";
import NftHeroCardRemaining from "../molecules/nftCards/NftHeroCardRemaining";
import useDropCalculations from "../../hooks/useDropCalculations";
import useCountdown from "../../hooks/useCountdown";

const ActiveCollectionDrops = ({ element, currentTime, totalDrops }) => {
  const [dropStarted, setDropStarted] = useState(false);
  const [yetToStart, expired] = useDropCalculations(
    element.drop_start,
    element.drop_end,
    currentTime
  );

  useEffect(() => {
    if (!yetToStart) {
      setDropStarted(true);
    }
  }, [yetToStart]);

  const [days, hours, minutes, seconds] = useCountdown(
    element?.drop_start,
    currentTime,
    yetToStart
  );

  if (days + hours + minutes + seconds === 0) {
    setDropStarted(true);
  }

  if (expired) {
    if (totalDrops === 1) {
      return (
        <h1 className="text-3xl text-primary text-center py-5">
          no active drops
        </h1>
      );
    }
    return null;
  }

  return (
    <>
      {element.aspen_tokens.map((item) => {
        if (!dropStarted) {
          return (
            <NftHeroCardTime
              key={item.id}
              name={item.name}
              nftCardImg={item.featured_image?.url}
              slug={item.slug}
              collectionSlug={item.aspen_collection?.artefy_collection?.slug}
              days={days}
              hours={hours}
              minutes={minutes}
              seconds={seconds}
            />
          );
        } else {
          return (
            <NftHeroCardRemaining
              key={item.id}
              name={item.name}
              nftCardImg={item.featured_image?.url}
              slug={item.slug}
              collectionSlug={item.aspen_collection?.artefy_collection?.slug}
              item={item}
            />
          );
        }
      })}
    </>
  );
};

export default ActiveCollectionDrops;
